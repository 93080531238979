import { Contact } from "../models/contact.model";
import api from "./api";

const END_POINT = "/contact";

const contactService = {
  get: async () => {
    try {
      return await api.get<Contact>(END_POINT);
    } catch (error: any) {
      throw new Error(error);
    }
  },
};

export default contactService;
