import { Product } from "../models/product.model";
import { DeliveryData } from "../models/melhor-envio/delivery-data.model";
import api from "./api";
import { OrderItem } from "../models/order-item.model";

export const melhorEnvioService = {


  calculate: async (cep: string, items: OrderItem[], state?: string) => {
    try {
      return await api.post<DeliveryData[]>("/melhor-envio/calculate", items, {
        params: {
          cep,
          state
        },
      });
    } catch (error) { }
  }
};

export default melhorEnvioService;
