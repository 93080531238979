import * as React from "react";
import { PageLoading } from "../styles/components/page-loading.emotion";

interface Props {
  active: boolean;
}

export default function PageLoadingComponent({ active }: Props) {
  return (
    <PageLoading active={active}>
      <span className="loader"></span>
    </PageLoading>
  );
}
