import { Order } from "./order.model";
import { ProductVariation } from "./product-variation.model";

export class OrderItem {
  id = "";
  quantity = 0;
  createdAt?: Date;
  updatedAt?: Date;
  idProductVariation = "";
  idOrder = "";
  valueTotal = 0;

  productVariation?: ProductVariation;
  order?: Order;
}
