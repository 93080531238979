import { GeneralRegister } from "../models/general-register.model";
import api from "./api";

const END_POINT = "/general-register";

const generalRegisterService = {
  get: async () => {
    try {
      return await api.get<GeneralRegister>(END_POINT);
    } catch (error: any) {
      throw new Error(error);
    }
  },
};

export default generalRegisterService;
