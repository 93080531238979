export class GeneralRegister {
  id = "";
  contactDescription = "";
  contactTitle = "";
  productHomeDescription = "";
  productHomeImage = "";
  productHomeTitle = "";
  howToBuy = "";
  exchangesAndReturns = "";
  purchaseAndShipping = null;
  privacyPolicy = "";
  commonQuestions = "";
  deadline = "";
}
