import { css, CSSObject, Global } from "@emotion/react";
import styled from "@emotion/styled";
import {
  BreakpointsType,
  useBreakpoint,
  useMediaQuery,
} from "./responsive.emotion";

export default function GlobalCSS() {
  return (
    <Global
      styles={css`
        :root {
          --transition-base: all 200ms ease-in-out;
          --height-thumb: 490px;
        }

        body {
          overflow-x: hidden;
        }

        html,
        body,
        #__next {
          height: 100%;
        }

        #__next {
          display: flex;
          flex-direction: column;
        }

        main {
          flex-grow: 1;
        }

        ${useMediaQuery("sm", "min")} {
          [data-hidden-desktop] {
            display: none !important;
          }
        }

        ${useMediaQuery("sm", "max")} {
          [data-hidden-mobile] {
            display: none !important;
          }
        }

        .fader {
          height: 300px;
          position: relative;
          overflow: hidden;
        }

        .fader__slide {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }

        .backdrop {
          transition: all 300ms;
          background-color: #000;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          pointer-events: none;
          z-index: 999;
          &.active {
            opacity: 0.4;
            pointer-events: auto;
          }
        }

        .fab-button {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #000;
          font-size: 20px;
          margin-left: auto;
          i {
            margin-left: 0.5rem;
          }
        }

        .overlay {
          position: fixed;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          transition: all 300ms;
          opacity: 0;
          pointer-events: none;
          backdrop-filter: blur(2em);
          z-index: 3;
          &.active {
            opacity: 0.7;
            pointer-events: auto;
          }
        }

        .full-loading {
          position: fixed;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 3.2em;
          color: white;
          background-color: rgba(0, 0, 0, 0.2);
          z-index: 3;
        }
      `}
    />
  );
}

export const Container = styled.div<{ size?: BreakpointsType }>`
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
  max-width: 1720px;

  ${(props) =>
    props.size &&
    css`
      max-width: ${useBreakpoint(props.size)};
    `}
`;

export const Wrapper = styled.div<{
  readonly flexDirection?: string;
  readonly flexWrap?: string;
  readonly justifyContent?: string;
  readonly gap?: string;
  readonly alignItems?: string;
  mobileOptions?: CSSObject;
}>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  flex-wrap: ${(props) => props.flexWrap || "nowrap"};
  align-items: ${(props) => props.alignItems || "normal"};
  justify-content: ${(props) => props.justifyContent || "normal"};
  gap: ${(props) => props.gap || "inherit"};

  ${() => useMediaQuery("sm", "max")} {
    ${(props) => props.mobileOptions}
  }
`;
