import * as React from "react";
import { Contact } from "../models/contact.model";
import { GeneralRegister } from "../models/general-register.model";
import contactService from "../service/contact.service";
import generalRegisterService from "../service/general-register.service";

interface GlobalContextData {
  generalRegister: GeneralRegister;
  contact: Contact;
}

const GlobalContext = React.createContext<GlobalContextData>(
  {} as GlobalContextData
);

interface GlobalProviderProps {
  children: React.ReactNode;
}

export const GlobalProvider = ({ ...props }: GlobalProviderProps) => {
  const [contact, setContact] = React.useState(new Contact());
  const [generalRegister, setGeneralRegister] = React.useState(
    new GeneralRegister()
  );

  React.useEffect(() => {
    getContact();
    getGeneralRegister();
  }, []);

  async function getContact() {
    const response = await contactService.get();
    setContact(response?.data);
  }

  async function getGeneralRegister() {
    const response = await generalRegisterService.get();
    setGeneralRegister(response?.data);
  }

  const context = {
    contact,
    generalRegister,
  };

  return (
    <GlobalContext.Provider value={context}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = React.useContext(GlobalContext);
  return context;
};

export default GlobalProvider;
