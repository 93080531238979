export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbpixel = {
  pageview: () => {
    window.fbq("track", "PageView");
  },
  event: (name: string, options = {} as facebook.Pixel.EventIDOptions) => {
    window.fbq("track", name, options);
  },
};
