export interface ThemeProps {
  colors: {
    green: string;
    green_pay: string;
    green_correct: string;
    sand: string;
    black: string;
    white: string;
    snow: string;
    gray_light: string;
    gray: string;
    gray_dark: string;
    dark: string;
    text: string;
    danger?: string;
    whatsApp?: string;
    pix?: string;
  };
}

const theme: ThemeProps = {
  colors: {
    green: "#736635",
    whatsApp: "#26d366",
    green_pay: "#00AC73",
    green_correct: "#00AC73",
    pix: "#32B6AA",
    sand: "#aba386",
    white: "#ffffff",
    snow: "#f7f7f7",
    gray_light: "#F1F0EB",
    gray: "#d5d1c2",
    gray_dark: "#717171",
    black: "#000000",
    dark: "#272727",
    text: "#404040",
    danger: "#dc3545",
  },
};

export default theme;
