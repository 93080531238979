import "@fortawesome/fontawesome-free/css/all.css";
import "keen-slider/keen-slider.min.css";
import "video-react/dist/video-react.css";
import * as React from "react";
import { ThemeProvider } from "@emotion/react";
import type { AppProps } from "next/app";
import theme from "../styles/theme";
import GlobalCSS from "../styles/utils/global.emotion";
import ResetCSS from "../styles/utils/reset.emotion";
import TypograthCSS from "../styles/utils/typograth.emotion";
import { DefaultSeo } from "next-seo";
import SEO from "../../next-seo.config";
import NextNProgress from "nextjs-progressbar";

import { useRouter } from "next/router";
import PageLoadingComponent from "../components/page-loading.component";
import AlertProvider from "../context/alert.context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import GlobalProvider from "../context/global.context";
import * as gtag from "../lib/gtag";
import Script from "next/script";
import CartProvider from "../context/cart.context";
import { createStore, StateMachineProvider } from "little-state-machine";

import { Product } from "../models/product.model";
import { FB_PIXEL_ID, fbpixel } from "../lib/fbpixel";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();

const getStorageType = () => {
  return typeof window !== "undefined" ? localStorage : undefined;
};

createStore(
  {
    order: {
      name: "",
      cpf: "",
      email: "",
      phone: "",
      paymentMethod: "",
    },
    address: {
      cep: "",
      state: "",
      city: "",
      neighborhood: "",
      street: "",
      number: "",
      complement: "",
    },
    freightValue: {
      deliveryForecast: "",
      value: 0,
    },
    installments: 1,
  },
  { storageType: getStorageType() }
);

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const [isReady, setReady] = React.useState(false);

  React.useEffect(() => {
    router.isReady && setReady(true);
  }, [router.isReady]);

  React.useEffect(() => {
    const handleComplete = (url: string) => {
      try {
        if (url?.startsWith("/obrigado")) {
          fbpixel.event("Purchase");
        } else if (url?.startsWith("/pagamento")) {
          fbpixel.event("Checkout");
        } else if (url?.startsWith("/checkout")) {
          fbpixel.event("Add To Cart");
        } else if (url?.startsWith("/produto/")) {
          fbpixel.event("View Content");
        } else {
          fbpixel.pageview();
        }
        gtag.pageview(url);
      } catch (error) { }
    };

    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router.asPath, router.events]);

  return (
    <>
      <Script
        id="goadopt"
        src="//tag.goadopt.io/injector.js?website_code=db32c42d-ec98-43c0-abe6-50299fb424dc"
        className="adopt-injector"
        strategy="afterInteractive"
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W93T2G9');
          `,
        }}
      />
      <Script
        id="goadopt"
        src="//tag.goadopt.io/injector.js?website_code=db32c42d-ec98-43c0-abe6-50299fb424dc"
        className="adopt-injector"
        strategy="afterInteractive"
      />
      <Script
        id="fb-pixel"
        src="/scripts/pixel.js"
        strategy="afterInteractive"
        data-pixel-id={FB_PIXEL_ID}
      />
      <NextNProgress color="#aba386" />
      <DefaultSeo {...SEO} />
      <QueryClientProvider client={queryClient}>
        <StateMachineProvider>
          <GlobalProvider>
            <ThemeProvider theme={theme}>
              <ResetCSS />
              <GlobalCSS />
              <TypograthCSS
                fontStyle={{
                  fontFamily: "'Montserrat', sans-serif",
                }}
              />
              <PageLoadingComponent active={!isReady} />
              <AlertProvider>
                <CartProvider>
                  <Component {...pageProps} />
                </CartProvider>
              </AlertProvider>
              <Toaster position="bottom-right" />
            </ThemeProvider>
          </GlobalProvider>
        </StateMachineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
