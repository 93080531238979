import * as React from "react";
import { Alert } from "../styles/components/alert.emotion";
import { Button } from "../styles/components/button.emotion";
import { Description, SubTitle } from "../styles/utils/typograth.emotion";

interface AlertData {
  title: string;
  message: string;
  buttonText?: string;
}

interface AlertContextData {
  presentAlert: (data: AlertData) => void;
}

const AlertContext = React.createContext<AlertContextData>(
  {} as AlertContextData
);

interface AlertProviderProps {
  children: React.ReactNode;
}

export const AlertProvider = ({ ...props }: AlertProviderProps) => {
  const [isOpen, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [buttonText, setButtonText] = React.useState("");

  function presentAlert(data: AlertData) {
    setOpen(true);
    setTitle(data?.title);
    setMessage(data?.message);
    setButtonText(data?.buttonText ?? "OK");
  }

  function dismissAlert() {
    setOpen(false);
    setTimeout(() => {
      setTitle("");
      setMessage("");
      setButtonText("");
    }, 300);
  }

  const context = {
    presentAlert,
  };

  return (
    <AlertContext.Provider value={context}>
      {props.children}
      <Alert open={isOpen}>
        <div className="backdrop" onClick={dismissAlert}></div>
        <div className="box">
          <SubTitle textAlign="center" color="dark">
            {title}
          </SubTitle>
          <Description color="black">{message}</Description>
          <Button onClick={dismissAlert} buttonStyle="outlined">
            <span>{buttonText}</span>
          </Button>
        </div>
      </Alert>
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = React.useContext(AlertContext);
  return context;
};

export default AlertProvider;
