import { OrderItem } from "./order-item.model";

export class Order {
  id = "";
  code = 0;
  name = "";
  email = "";
  document = "";
  phone = "";
  country = "";
  state = "";
  city = "";
  zip_code = "";
  line_1 = "";
  line_2 = "";
  paymentMethod = "";
  installments = 1;
  status = "";
  createdAt = new Date();
  updatedAt?: Date;
  valueFreight = 0;
  value = 0;
  idOrderPagarme = "";

  items: OrderItem[] = [];
}

// front
export class OrderAddress {
  cep = "";
  state = "";
  city = "";
  neighborhood = "";
  street = "";
  number = "";
  complement = "";
}
