import { Global, css } from "@emotion/react";

export default function ResetCSS() {
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
        }

        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          border: 0;
          font-size: 100%;
          margin: 0;
          padding: 0;
          vertical-align: baseline;
          font: inherit;
        }

        :focus {
          outline: 0;
        }

        ::-moz-selection {
          background: #b3d4fc;
          text-shadow: none;
        }

        ::selection {
          background: #b3d4fc;
          text-shadow: none;
        }

        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
          display: block;
        }

        a {
          text-decoration: none;
        }

        abbr {
          text-decoration: none;
        }

        body {
          line-height: 1;
        }

        main {
          overflow: hidden;
        }

        ol,
        ul {
          list-style: none;
        }

        blockquote,
        q {
          quotes: none;
        }

        blockquote::before,
        blockquote::after,
        q::before,
        q::after {
          content: none;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
        }

        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
          -webkit-appearance: none;
          -moz-appearance: none;
        }

        input[type="search"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
          box-sizing: content-box;
        }

        textarea {
          overflow: auto;
          resize: vertical;
          vertical-align: top;
          overflow: auto;
          vertical-align: top;
          resize: vertical;
          font: inherit;
        }

        audio,
        canvas,
        video {
          display: inline-block;
          max-width: 100%;
          zoom: 1;
        }

        audio:not([controls]) {
          display: none;
          height: 0;
        }

        [hidden] {
          display: none;
        }

        html {
          font-size: 100%;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
        }

        a:focus {
          outline: none;
        }

        a:active,
        a:hover {
          outline: 0;
        }

        img {
          border: 0;
          -ms-interpolation-mode: bicubic;
          max-width: 100%;
          vertical-align: middle;
        }

        figure {
          margin: 0;
        }

        form {
          margin: 0;
        }

        fieldset {
          border: 1px solid #c0c0c0;
          margin: 0 2px;
          padding: 0.35em 0.625em 0.75em;
          border: 0;
          margin: 0;
          padding: 0;
        }

        legend {
          border: 0;
          margin-left: -7px;
          padding: 0;
          white-space: normal;
        }

        button,
        input,
        select,
        textarea {
          font-size: 100%;
          margin: 0;
          vertical-align: middle;
        }

        button,
        input {
          line-height: normal;
        }

        button,
        select {
          text-transform: none;
        }

        button,
        html input[type="button"],
        input[type="reset"],
        input[type="submit"] {
          -webkit-appearance: button;
          cursor: pointer;
          *overflow: visible;
        }

        button[disabled],
        html input[disabled] {
          cursor: default;
        }

        input[type="checkbox"],
        input[type="radio"] {
          box-sizing: border-box;
          height: 13px;
          padding: 0;
          width: 13px;
        }

        input[type="search"] {
          -webkit-appearance: textfield;
          -moz-box-sizing: content-box;
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
        }

        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-decoration {
          -webkit-appearance: none;
        }

        button::-moz-focus-inner,
        input::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
        }

        html,
        button,
        input,
        select,
        textarea {
          color: #222;
        }

        .chromeframe {
          background: #ccc;
          color: #000;
          margin: 0.2em 0;
          padding: 0.2em 0;
        }

        button {
          background: unset;
          border: unset;
          padding: 0;
          transition: all 250ms ease-in-out;
        }

        input,
        textarea {
          border: none;
        }

        strong,
        b {
          font-weight: 700;
        }

        hr {
          border: unset;
          height: 1px;
          margin: unset;
        }
      `}
    />
  );
}
