import { ProductVariation } from "../models/product-variation.model";
import { Product } from "../models/product.model";
import { FetchProps } from "../shared/helper";
import api from "./api";

const END_POINT = "/product-variation";

export interface ProductVariationGetProps {
  filter?: string;
  page?: number;
  count?: number;
  orderColumn?: string;
  orderDirection?: string;
  situation?: string;
  idProduct?: string;
}

const productVariationService = {
  get: async (params?: ProductVariationGetProps) => {
    return await api.get<FetchProps<ProductVariation>>(END_POINT, {
      params,
    });
  },

  getBySlug: async (slug?: string) => {
    return await api.get<ProductVariation>(END_POINT + "/slug/" + slug);
  },
};

export default productVariationService;
