import { css, CSSObject, Global } from "@emotion/react";
import styled from "@emotion/styled";
import { ThemeProps } from "../theme";

interface Props {
  fontStyle: CSSObject;
}

export default function TypograthCSS({ fontStyle }: Props) {
  return (
    <Global
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800&display=swap");

        html {
          font-size: 16px;
          ${fontStyle}
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a,
        span {
          font-size: clamp(var(--min), var(--val), var(--max));
        }

        a,
        span {
          --min: 1rem;
          --val: 2.5vw;
          --max: 1em;
        }

        p {
          --min: 0.9em;
          --val: 2.5vw;
          --max: 1.125em;
        }
      `}
    />
  );
}

export const Title = styled.h2<{
  color?: keyof ThemeProps["colors"];
  textAlign?: string;
}>`
  --min: 2em;
  --val: 3.5vw;
  --max: 3.5em;
  position: relative;
  font-weight: 800;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.text};
`;

export const SubTitle = styled.h2<{
  color?: keyof ThemeProps["colors"];
  textAlign?: "left" | "center" | "right";
}>`
  --min: 2em;
  --val: 3vw;
  --max: 2.5em;
  position: relative;
  font-weight: 800;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.text};
`;

export const Description = styled.p<{
  color?: keyof ThemeProps["colors"];
  size?: "default" | "small";
}>`
  line-height: 1.5;
  font-weight: 300;
  white-space: pre-wrap;
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.text};

  ${(props) =>
    (!props.size || props.size === "default") &&
    css`
      --min: 1rem;
      --val: 1vw;
      --max: 1.125rem;
      font-weight: 400;
    `}

  ${(props) =>
    props.size === "small" &&
    css`
      --min: 0.85em;
      --val: 1.125vw;
      --max: 1em;
      font-weight: 400;
    `}
`;

export const HTMLText = styled.div`
  h2,
  h3 {
    --min: 1.125em;
    --val: 1.25vw;
    --max: 1.5em;
    font-weight: 700;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.colors.green};
  }

  p {
    --min: 1rem;
    --val: 1vw;
    --max: 1.125rem;
    line-height: 2;
    color: ${(props) => props.theme.colors.text};
    margin-bottom: 30px;
  }

  a {
    color: ${(props) => props.theme.colors.green};
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
`;
