import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const PageLoading = styled.div<{
  active?: boolean;
}>`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.green};
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 180ms ease-in-out;
  .loader {
  width: 48px;
  height: 48px;
  background: #FFF;
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  animation: animloader 1s ease-in infinite;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
} 

  .water-mark {
    img {
      animation-duration: 10s;
    }
  }

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;
