export type BreakpointsType = "xxs" | "xs" | "sm" | "md" | "lg" | "xg" | "xxg";

export const useBreakpoint = (bp: BreakpointsType) => {
  const aux = {
    xxs: "576px",
    xs: "768px",
    sm: "992px",
    md: "1200px",
    lg: "1440px",
    xg: "1600px",
    xxg: "1920px",
  };

  return aux[bp];
};

export const useMediaQuery = (
  bp: BreakpointsType,
  direction: "min" | "max" = "min"
) => `@media (${direction}-width: ${useBreakpoint(bp)})`;
