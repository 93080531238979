const title = "Bomarzo Design | Peças incríveis e exclusivas";
const description =
  "Proporcionar aos nossos clientes produtos inovadores, com alta qualidade, design moderno e elegante. Onde possa agregar valor estético ao seu lar ou local de trabalho; tornando o mesmo um ambiente mais agradável, belo e ornamentado. ";

const SEO = {
  title,
  description,
  canonical: "/",
  openGraph: {
    url: "https://www.bomarzodesign.com.br",
    title,
    description,
    images: [
      {
        url: "/avatar.png",
        width: 1200,
        height: 630,
        alt: "Seja bem-vindo!",
        type: "image/jpeg",
      },
    ],
    site_name: "Bomarzo",
    type: "website",
  },
  twitter: {
    handle: "@bomarzodesign",
    site: "@bomarzodesign",
    cardType: "summary_large_image",
  },
  robotsProps: {
    notranslate: true,
  },
};

export default SEO;
