import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMediaQuery } from "../utils/responsive.emotion";

type ButtonStyle = "default" | "outlined";
type ButtonSize = "small" | "default";

export const Button = styled.button<{
  readonly buttonStyle?: ButtonStyle;
  readonly buttonSize?: ButtonSize;
  readonly block?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  padding: 0 2em;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  transition: var(--transition-base);
  width: 100%;
  margin-top: 1rem;
  cursor: pointer;

  span {
    --min: 0.8em;
    --val: 1vw;
    --max: 1em;
    font-weight: 700;
    transition: var(--transition-base);
    text-align: center;
    text-transform: uppercase;
  }

  i {
    font-size: 1.25em;
    margin-right: 0.75rem;
  }

  ${() => useMediaQuery("sm")} {
    min-width: 12.5rem;
    width: auto;

    ${(props) =>
      props.block &&
      css`
        width: 100%;
        flex: 0 0 100%;
      `}
  }

  ${(props) =>
    props.buttonStyle === "default" &&
    css`
      background-color: ${props.theme.colors.green};
      border-color: ${props.theme.colors.green};
      box-shadow: 0px 8px 10px #15151920;

      span,
      i {
        color: ${props.theme.colors.white};
      }

      &:hover {
        background-color: ${props.theme.colors.sand};
        border-color: ${props.theme.colors.sand};

        i,
        span {
          color: ${props.theme.colors.dark};
        }
      }
    `}

  ${(props) =>
    props.buttonStyle === "outlined" &&
    css`
      background-color: transparent;
      border-color: ${props.theme.colors.dark};

      span,
      i {
        color: ${props.theme.colors.dark};
      }

      &:hover {
        background-color: ${props.theme.colors.dark};
        box-shadow: 0px 10px 30px #1515197d;

        i,
        span {
          color: ${props.theme.colors.white};
        }
      }
    `}

  ${(props) =>
    props.buttonSize === "small" &&
    css`
      height: 3.125rem;

      span {
        --min: 0.8em;
        --val: 1vw;
        --max: 1em;
      }
    `}
`;
