export class Contact {
  id = "";
  address = "";
  email = "";
  phone = "";
  whatsapp = "";
  facebook = "";
  instagram = "";
  youtube = "";
  pinterest = "";
  linkedin = "";
  tiktok = "";
  openingHours = "";
}
