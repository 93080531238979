import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Alert = styled.div<{
  open?: boolean;
}>`
  z-index: 9999;
  position: relative;

  .box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.colors.white};
    padding: min(10vw, 5rem);
    max-width: 640px;
    width: 90%;
    border-top: 10px solid ${(props) => props.theme.colors.green};
    transition: all 300ms;
    opacity: 0;
    pointer-events: none;
    border-radius: 30px;
    z-index: 999;
    p {
      text-align: center;
      margin-top: 2vw;
      font-size: 18px;
    }

    button {
      display: block;
      margin: 3.125rem auto 0;
    }
  }

  .backdrop {
    transition: all 300ms;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    backdrop-filter: blur(0.5rem);
  }

  ${(props) =>
    props.open &&
    css`
      background-color: red;

      .box {
        opacity: 1;
        pointer-events: auto;
      }

      .backdrop {
        opacity: 1;
        pointer-events: auto;
      }
    `}
`;
